<!--
 * @Descripttion: 显示抽取完成的项目 专家抽取记录
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-02-19 09:51:37
-->
<template>
	<div class="vh bg">
		<van-nav-bar title="记录详情" left-arrow @click-left="goBack" />
		<div class="pad">
			<div class="whiteBg">
				<h3 class="fc">{{ record.expertName }}</h3>
				<p>
					手机号： {{ record.expertPhone }}
					<a :href="'tel:' + record.expertPhone "><i class="icon-phone wh05 mar-l2"></i></a>
				</p>
				<div v-if="record.majorName">
					<h3>评审专业</h3>
					<p class="greenLi van-ellipsis">{{ record.majorParentName + '-' + record.majorName }}</p>
				</div>
				<p>是否参与</p>
				<van-radio-group v-model="record.ifAttend" direction="horizontal" checked-color="#00CCB2" class="mar-t2">
					<van-radio name="1">参加</van-radio>
					<van-radio name="0">不参加</van-radio>
				</van-radio-group>
				<p v-if="record.ifAttend =='0'"> {{ record.noAttendReason }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		RadioGroup,
		Radio
	} from 'vant'

	export default {

		data() {
			return {
				record: {},
				id: '',
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			[Radio.name]: Radio,
			[RadioGroup.name]: RadioGroup
		},
		mounted() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			this.id = this.$route.query.id
			this.getMojorName();
		},
		methods: {
			goBack() {
				this.$router.push('/projectInfo')
			},

			getMojorName() {
				this.$toast.loading('加载中......')
				this.$ajax({
					method: "get",
					url: "/gateway/ed/wx/wxEdProjectController/getExpertRecordById",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						id: this.id,
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
				}).then(
					({
						data
					}) => {
						this.$toast.clear()
						if (data.success) {
							this.record = data.result
						} else {
							this.$toast.fail({
								message: data.message,
								type: 'fail',
								className: 'div3'
							})
						}
					}
				).catch(() => {
					this.$toast.fail('请求失败')
				})
			}
		}
	}
</script>

<style scoped>
	.whiteBg {
		padding: 0.3rem;
		border-radius: 0.2rem;
	}

	.whiteBg p,
	.whiteBg h3 {
		margin-top: 0.2rem
	}

	.whiteBg:not(:first-child) {
		margin-top: 0.3rem;
	}

	.van-radio {
		pointer-events: none
	}
</style>
